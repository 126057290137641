    import React, { useState, useEffect } from 'react';
    import axios from 'axios';
    import { FaTemperatureFull } from "react-icons/fa6";
    import { FaCloud } from "react-icons/fa";
    import { FiSunset } from "react-icons/fi";
    import { FiSunrise } from "react-icons/fi";
    import { Swiper, SwiperSlide } from 'swiper/react';
    import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';

    const Banner = () => {
        const [temperature, setTemperature] = useState(null);
        const [temperature2, setTemperature2] = useState(null);
        const [temperature3, setTemperature3] = useState(null);
        const [temperature4, setTemperature4] = useState(null);
        const [sunrise, setSunrise] = useState(null);
        const [sunrise3, setSunrise3] = useState(null);
        const [susnset, setSusnset] = useState(null);
        const [susnset3, setSusnset3] = useState(null);
        const [weatherDescription, setWeatherDescription] = useState('');
        const [weatherDescription2, setWeatherDescription2] = useState('');
        const [weatherDescription4, setWeatherDescription4] = useState('');
        const [weatherDescription3, setWeatherDescription3] = useState('');
        const [iconUrl, setIconUrl] = useState('');
        const [iconUrl3, setIconUrl3] = useState('');
        const [lastAPICallTime, setLastAPICallTime] = useState('');
        const [lastAPICallTime3, setLastAPICallTime3] = useState('');
        const [currentDate, setCurrentDate] = useState('');
        const [currentDate3, setCurrentDate3] = useState('');
        const [cloudiness, setCloudiness] = useState(null);
        const [cloudiness2, setCloudiness2] = useState(null);
        const [cloudiness3, setCloudiness3] = useState(null);
        const [cloudiness4, setCloudiness4] = useState(null);



        useEffect(() => {
            const fetchWeatherData = async () => {
                try {
                    const apiKey = process.env.REACT_APP_TOKEN; // Replace with your API key
                    const lat = "47.894227633313164";
                    const long = "20.700419397992786"
                    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&appid=${apiKey}&units=metric&lang=hu`;
                    const response = await axios.get(url);
                    setTemperature(response.data.main.temp);
                    const sunriseTime = new Date(response.data.sys.sunrise * 1000); // Convert Unix timestamp to JavaScript Date object
                    setSunrise(sunriseTime.toLocaleTimeString());
                    const sunsetTime = new Date(response.data.sys.sunset * 1000); // Convert Unix timestamp to JavaScript Date object
                    setSusnset(sunsetTime.toLocaleTimeString());
                    const weather = response.data.weather[0];
                    setWeatherDescription(weather.description);
                    const iconCode = weather.icon;
                    setIconUrl(`https://openweathermap.org/img/wn/${iconCode}.png`);
                    const currentTime = new Date();
                    setLastAPICallTime(currentTime.toLocaleTimeString('HU', { hour: '2-digit', minute: '2-digit' }));
                    const today = new Date();
                    setCurrentDate(today.toLocaleDateString('HU', { weekday: 'long', month: 'long', day: 'numeric' }));
                    const cloudPercentage = response.data.clouds ? response.data.clouds.all : 0;
                    setCloudiness(cloudPercentage);

                } catch (error) {
                    console.error("Error fetching weather data:", error);
                }
            };
            fetchWeatherData();
        }, []);


        useEffect(() => {
            const fetchWeatherData2 = async () => {
                try {
                    const apiKey = process.env.REACT_APP_TOKEN; // Replace with your API key
                    const lat2 = "47.894227633313164";
                    const long2 = "20.700419397992786"
                    const url2 = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat2}&lon=${long2}&appid=${apiKey}&units=metric&lang=hu`;
                    const response2 = await axios.get(url2);
                    setTemperature2(response2.data.list[0].main.temp); //0 will be the 3 hour forecast, 1 will be 4 hour forecast etc

                    const cloudPercentage2 = response2.data.list[0].clouds.all;
                    setCloudiness2(cloudPercentage2);

                    const weather2 = response2.data.list[0].weather[0].main;
                    setWeatherDescription2(weather2.description);

                } catch (error) {
                    console.error("Error fetching weather data:", error);
                }
            };
            fetchWeatherData2();
        }, []);


        useEffect(() => {
            const fetchWeatherData3 = async () => {
                try {
                    const apiKey3 = process.env.REACT_APP_TOKEN; // Replace with your API key
                    const lat3 = "47.752511225144204";
                    const long3 = "20.084660674693907"
                    const url3 = `https://api.openweathermap.org/data/2.5/weather?lat=${lat3}&lon=${long3}&appid=${apiKey3}&units=metric&lang=hu`;
                    const response3 = await axios.get(url3);
                    setTemperature3(response3.data.main.temp);
                    const sunriseTime3 = new Date(response3.data.sys.sunrise * 1000); // Convert Unix timestamp to JavaScript Date object
                    setSunrise3(sunriseTime3.toLocaleTimeString());
                    const sunsetTime3 = new Date(response3.data.sys.sunset * 1000); // Convert Unix timestamp to JavaScript Date object
                    setSusnset3(sunsetTime3.toLocaleTimeString());
                    const weather3 = response3.data.weather[0];
                    setWeatherDescription3(weather3.description);
                    const iconCode3 = weather3.icon;
                    setIconUrl3(`https://openweathermap.org/img/wn/${iconCode3}.png`);
                    const currentTime3 = new Date();
                    setLastAPICallTime3(currentTime3.toLocaleTimeString('HU', { hour: '2-digit', minute: '2-digit' }));
                    const today3 = new Date();
                    setCurrentDate3(today3.toLocaleDateString('HU', { weekday: 'long', month: 'long', day: 'numeric' }));
                    const cloudPercentage3 = response3.data.clouds ? response3.data.clouds.all : 0;
                    setCloudiness3(cloudPercentage3);

                } catch (error) {
                    console.error("Error fetching weather data:", error);
                }
            };
            fetchWeatherData3();
        }, []);


        useEffect(() => {
            const fetchWeatherData4 = async () => {
                try {
                    const apiKey4 = process.env.REACT_APP_TOKEN; // Replace with your API key
                    const lat4 = "47.752511225144204";
                    const long4 = "20.084660674693907"
                    const url4 = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat4}&lon=${long4}&appid=${apiKey4}&units=metric&lang=hu`;
                    const response4 = await axios.get(url4);
                    setTemperature4(response4.data.list[0].main.temp); //0 will be the 3 hour forecast, 1 will be 4 hour forecast etc

                    const cloudPercentage4 = response4.data.list[0].clouds.all;
                    setCloudiness4(cloudPercentage4);

                    const weather4 = response4.data.list[0].weather[0].main;
                    setWeatherDescription4(weather4.description);

                } catch (error) {
                    console.error("Error fetching weather data:", error);
                }
            };
            fetchWeatherData4();
        }, []);




        return (
            <>
            <div className='main bannner' id="home">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}


                    pagination={{ clickable: true }}
                >
                    <SwiperSlide><div className="banner-container">

                        <div className="banner-layer"></div>
                        <div className="banner-text-container">
                            <div className="banner-title">
                                <h5>Bükkábrány</h5>
                                {weatherDescription && (
                                    <div className='banner-ic'>
                                        <img src={iconUrl} alt="Weather icon" />
                                        <span>{weatherDescription}</span>
                                    </div>
                                )}
                            </div>
                            <div className="banner-data">
                                <div className="baner-data1"> {currentDate && <p>{currentDate}</p>}</div>
                                <div className="baner-data2"> {lastAPICallTime && <p>Frissítve: {lastAPICallTime}</p>}</div>
                            </div>
                            <div className="div_flex">

                                <div className="banner-main-container">
                                    <div className="banner-item">
                                        <b>Aktuális</b>
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaTemperatureFull />
                                        </div>
                                        {temperature ? ` ${temperature}°C` : 'Töltés...'}
                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FiSunrise />
                                        </div>
                                        {sunrise ? ` ${sunrise}` : 'Töltés...'}
                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaCloud />
                                        </div>
                                        {cloudiness !== null && <p> {cloudiness}%</p>}
                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FiSunset />
                                        </div>
                                        {susnset ? ` ${susnset}` : 'Töltés...'}
                                    </div>

                                </div>
                                <div className="banner-main-container">
                                    <div className="banner-item">
                                        <b>3 órás előrejelzés</b>
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaTemperatureFull />
                                        </div>
                                        {temperature2 ? ` ${temperature2}°C` : 'Töltés...'}
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaCloud />
                                        </div>
                                        {cloudiness2 !== null && <p> {cloudiness2}%</p>}
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    </SwiperSlide>
                    <SwiperSlide><div className="banner-container">
                        <div className="banner-layer"></div>
                        <div className="banner-text-container">
                            <div className="banner-title">
                                <h5>Halmajugra</h5>
                                {weatherDescription3 && (
                                    <div className='banner-ic'>
                                        <img src={iconUrl3} alt="Weather icon" />
                                        <span>{weatherDescription3}</span>
                                    </div>
                                )}
                            </div>
                            <div className="banner-data">
                                <div className="baner-data1"> {currentDate3 && <p>{currentDate3}</p>}</div>
                                <div className="baner-data2"> {lastAPICallTime3 && <p>Frissítve: {lastAPICallTime3}</p>}</div>
                            </div>
                            <div className="div_flex">

                                <div className="banner-main-container">
                                    <div className="banner-item">
                                        <b>Aktuális</b>
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaTemperatureFull />
                                        </div>
                                        {temperature3 ? ` ${temperature3}°C` : 'Töltés...'}
                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FiSunrise />
                                        </div>
                                        {sunrise3 ? ` ${sunrise3}` : 'Töltés...'}
                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaCloud />
                                        </div>
                                        {cloudiness3 !== null && <p> {cloudiness3}%</p>}
                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FiSunset />
                                        </div>
                                        {susnset3 ? ` ${susnset3}` : 'Töltés...'}
                                    </div>

                                </div>
                                <div className="banner-main-container">
                                    <div className="banner-item">
                                        <b>3 órás előrejelzés</b>
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaTemperatureFull />
                                        </div>
                                        {temperature4 ? ` ${temperature4}°C` : 'Töltés...'}
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                    <div className="banner-item">
                                        <div className="banner-item-icon">
                                            <FaCloud />
                                        </div>
                                        {cloudiness4 !== null && <p> {cloudiness4}%</p>}
                                    </div>
                                    <div className="banner-item">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            </>
        );
    }

    export default Banner;
